import React, {
  useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import {
  Box,
  Grid,
  Link,
  Button,
  MenuList,
  MenuItem,
  TextField,
  InputAdornment,
  IconButton,
  Grow,
  Paper,
  ClickAwayListener,
  Popper,
} from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { BiFilter, BiPlus } from 'react-icons/bi';
import { HiOutlineSearch } from 'react-icons/hi';
import PropTypes from 'prop-types';
import { fetchTasks } from '../../redux/actions/taskActions';
import RyffineTable from '../common/RyffineTable';
import { useSearch } from '../../core/hooks';
import { filterBySearch } from '../../core/services/taskConversionService';
import { formatDateStr } from '../../core/services/commonService';
import {
  TASKS_ROUTE,
  TYPE_NORMALIZATION,
  TYPE_CONVERSION,
  TYPES_FILTER,
  CREATE_TASK_ROUTE,
} from '../../core/constants';
import { FileEntity } from '../../core/entities';
import FiltersDialog from '../tasks/FiltersDialog';
import CreateNormalizationDialog from '../tasks/CreateNormalizationDialog';
import CreateConversionDialog from '../tasks/CreateConversionDialog';
import CreateIxiasoftDialog from '../tasks/CreateIxiasoftDialog';
import CreatePandocDialog from '../tasks/CreatePandocDialog';
import CreatePaligoDialog from '../tasks/CreatePaligoDialog';
import CreateSdlDialog from '../tasks/CreateSdlDialog';
import { ENABLED_TASKS, ENABLE_OLD_CREATE_CONVERSION } from '../../config';
import CreateFluidTopicsDialog from '../tasks/CreateFluidTopicsDialog';
import CreateGitHubDialog from '../tasks/CreateGitHubDialog';
import CreateKionSdlDialog from '../tasks/CreateKionSdlDialog';

const filterAndGenerateTableRows = (tasks, filename) => (
  tasks
    .filter(task => task.source === filename)
    .map((task, index) => ({
      id: index,
      uuid: {
        value: task.id,
        displayValue: <Link to={`${TASKS_ROUTE}/${task.id}`} component={RouterLink}>{task.id}</Link>,
      },
      output: (task.output || 'N/A').match(new RegExp('.{1,95}', 'g')).join('\n'),
      converter: task.converter,
      status: task.status,
      owner: task.owner,
      created: {
        value: task.created,
        displayValue: formatDateStr(task.created) || 'N/A',
      },
    }))
);

const columns = [
  {
    field: 'uuid', headerName: 'UUID', sortable: true, flex: 1,
  },
  {
    field: 'output', headerName: 'Output', sortable: true, flex: 1,
  },
  {
    field: 'converter', headerName: 'Type', sortable: true, flex: 0.5,
  },
  {
    field: 'status', headerName: 'Status', sortable: true, flex: 0.5,
  },
  {
    field: 'owner', headerName: 'Owner', sortable: true, flex: 0.5,
  },
  {
    field: 'created', headerName: 'Created Date', sortable: true, flex: 0.75,
  },
];

function FileDetailsTasks({ file }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useSearch();
  const { tasks, loading } = useSelector(state => state.task);

  const [openFiltersDialog, setOpenFiltersDialog] = useState(false);
  const [filters, setFilters] = useState({});

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const [openNormalizationTaskDialog, setOpenNormalizationTaskDialog] = useState(false);
  const [openFluidTopicsTaskDialog, setOpenFluidTopicsTaskDialog] = useState(false);
  const [openIxiasoftTaskDialog, setOpenIxiasoftTaskDialog] = useState(false);
  const [openPandocTaskDialog, setOpenPandocTaskDialog] = useState(false);
  const [openPaligoTaskDialog, setOpenPaligoTaskDialog] = useState(false);
  const [openCreateTaskDialog, setOpenCreateTaskDialog] = useState(false);
  const [openGitHubTaskDialog, setOpenGitHubTaskDialog] = useState(false);

  const [openKionSdlTaskDialog, setOpenKionSdlTaskDialog] = useState(false);
  const [openSdlTaskDialog, setOpenSdlTaskDialog] = useState(false);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    dispatch(fetchTasks());
  }, [dispatch]);

  const filteredTasks = useMemo(
    () => filterBySearch(tasks, searchValue, filters),
    [tasks, searchValue, filters],
  );

  const tableRows = useMemo(
    () => filterAndGenerateTableRows(filteredTasks, file.id),
    [filteredTasks, file.id],
  );

  const onClickSelectType = type => {
    const obj = { ...filters };

    if (obj.types) {
      if (obj.types.indexOf(type) === -1) obj.types.push(type);
      else {
        obj.types.splice(obj.types.indexOf(type), 1);

        switch (type) {
          case TYPE_NORMALIZATION:
            if (Object.keys(obj).includes('normalizationTag')) delete obj.normalizationTag;
            break;

          case TYPE_CONVERSION:
            if (Object.keys(obj).includes('conversionType')) delete obj.conversionType;
            if (Object.keys(obj).includes('conversionTag')) delete obj.conversionTag;
            break;

          default:
            break;
        }
      }
    } else obj.types = [type];

    setFilters(obj);
  };

  const filtersLength = Object.keys(filters).filter(k => k !== 'types').length || 0;

  const handleCreationForm = (changeValueMethod, link) => {
    changeValueMethod(false);

    if (link) history.push(link);
  };

  const rerunTask = {
    source: file.id,
    config: '{}',
  };

  return (
    <Grid container>
      <Grid className="pt-3" container item xs={12} alignItems="center">
        <Grid item xs={6} sm={6} md={8}>
          <Box sx={{ boxShadow: 0 }}>
            <TextField
              onChange={event => setSearchValue(event.target.value)}
              placeholder="Search folder, owner, etc."
              InputProps={{
                style: {
                  paddingBottom: '2.5px',
                  background: '#FFFFFF',
                  paddingTop: '2.5px',
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <HiOutlineSearch style={{ color: 'grey' }} />
                  </InputAdornment>
                ),
              }}
              value={searchValue}
              variant="outlined"
              size="small"
              id="search"
              fullWidth
            />
          </Box>
        </Grid>

        <Grid className="buttons-container" container item xs={6} sm={6} md={4} justifyContent="flex-end">
          <Button
            onClick={() => setOpenFiltersDialog(true)}
            variant="outlined"
            className="button"
            color="primary"
          >
            <IconButton className="outlined-button-icon"><BiFilter /></IconButton>
            Filters
          </Button>

          <Button
            onClick={() => setOpen(prevOpen => !prevOpen)}
            variant="contained"
            className="button"
            color="primary"
            ref={anchorRef}
          >
            <IconButton className="contained-button-icon"><BiPlus /></IconButton>
            Create task
          </Button>

          <Popper
            style={{ zIndex: 10 }}
            anchorEl={anchorRef.current}
            role={undefined}
            disablePortal
            open={open}
            transition
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList key="profileList" autoFocusItem={open} id="menu-list-grow">
                      {ENABLED_TASKS.conversion && (
                        <>
                          {ENABLE_OLD_CREATE_CONVERSION && (
                            <MenuItem
                              onClick={
                                () => history.push(
                                  CREATE_TASK_ROUTE, { task: { source: file.id } },
                                )
                              }
                            >
                              Conversion
                            </MenuItem>
                          )}

                          {!ENABLE_OLD_CREATE_CONVERSION && (
                            <MenuItem onClick={() => setOpenCreateTaskDialog(true)}>
                              Conversion
                            </MenuItem>
                          )}
                        </>

                      )}

                      {ENABLED_TASKS.normalization && (
                        <MenuItem
                          onClick={
                            () => setOpenNormalizationTaskDialog(true)
                          }
                        >
                          Normalization
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.pandoc && (
                        <MenuItem
                          onClick={() => setOpenPandocTaskDialog(true)}
                        >
                          Pandoc
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.ixiasoft && (
                        <MenuItem
                          onClick={() => setOpenIxiasoftTaskDialog(true)}
                        >
                          Ixiasoft
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.paligo && (
                        <MenuItem
                          onClick={
                            () => setOpenPaligoTaskDialog(true)
                          }
                        >
                          Paligo
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.kion_sdl && (
                        <MenuItem
                          onClick={
                            () => setOpenKionSdlTaskDialog(true)
                          }
                        >
                          Kion Tridion
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.sdl && (
                        <MenuItem
                          onClick={
                            () => setOpenSdlTaskDialog(true)
                          }
                        >
                          Tridion
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.fluidTopics && (
                        <MenuItem
                          onClick={
                            () => setOpenFluidTopicsTaskDialog(true)
                          }
                        >
                          Fluid Topics
                        </MenuItem>
                      )}

                      {ENABLED_TASKS.gitHub && (
                        <MenuItem
                          onClick={
                            () => setOpenGitHubTaskDialog(true)
                          }
                        >
                          GitHub
                        </MenuItem>
                      )}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </Grid>
      </Grid>

      <Grid className="pt-4" item xs={12}>
        <Grid container spacing={2} style={{ margin: '0px -8px 0px -8px' }}>
          {TYPES_FILTER.map(key => (
            <Grid item key={key}>
              <Button
                color={filters.types && filters.types.includes(key) ? 'primary' : 'default'}
                style={{ background: 'rgba(51, 51, 51, 0.05)' }}
                onClick={() => onClickSelectType(key)}
                className="status-button"
                variant="contained"
              >
                {key}
              </Button>
            </Grid>
          ))}

          {filtersLength > 0 && (
            <Grid item>
              <Button
                className="status-button"
                variant="contained"
                color="primary"
              >
                {`${filtersLength} filters`}
                <IconButton
                  onClick={() => setFilters(filters.types ? { types: filters.types } : {})}
                  className="contained-button-icon"
                  style={{ margin: '0 0 0 8px' }}
                >
                  <MdClose size={18} />
                </IconButton>
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <RyffineTable
          rows={tableRows}
          columns={columns}
          loading={loading}
          initSorting={{ order: 'desc', field: 'created' }}
          tableContainerProps={{ style: { paddingTop: '24px' } }}
        />
      </Grid>

      <FiltersDialog
        onClose={() => setOpenFiltersDialog(false)}
        onSubmit={values => setFilters(values)}
        open={openFiltersDialog}
        init={filters}
      />

      {openNormalizationTaskDialog && (
      <CreateNormalizationDialog
        rerunTask={rerunTask}
        open={openNormalizationTaskDialog}
        onClose={link => handleCreationForm(setOpenNormalizationTaskDialog, link)}
      />
      )}

      {openCreateTaskDialog && (
        <CreateConversionDialog
          rerunTask={rerunTask}
          open={openCreateTaskDialog}
          onClose={link => handleCreationForm(setOpenCreateTaskDialog, link)}
        />
      )}

      {openPandocTaskDialog && (
        <CreatePandocDialog
          rerunTask={rerunTask}
          open={openPandocTaskDialog}
          onClose={link => handleCreationForm(setOpenPandocTaskDialog, link)}
        />
      )}

      {openPaligoTaskDialog && (
        <CreatePaligoDialog
          rerunTask={rerunTask}
          open={openPaligoTaskDialog}
          onClose={link => handleCreationForm(setOpenPaligoTaskDialog, link)}
        />
      )}

      {openIxiasoftTaskDialog && (
        <CreateIxiasoftDialog
          rerunTask={rerunTask}
          open={openIxiasoftTaskDialog}
          onClose={link => handleCreationForm(setOpenIxiasoftTaskDialog, link)}
        />
      )}

      {openKionSdlTaskDialog && (
        <CreateKionSdlDialog
          rerunTask={rerunTask}
          open={openKionSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenKionSdlTaskDialog, link)}
        />
      )}

      {openSdlTaskDialog && (
        <CreateSdlDialog
          rerunTask={rerunTask}
          open={openSdlTaskDialog}
          onClose={link => handleCreationForm(setOpenSdlTaskDialog, link)}
        />
      )}

      {openFluidTopicsTaskDialog && (
        <CreateFluidTopicsDialog
          rerunTask={rerunTask}
          open={openFluidTopicsTaskDialog}
          onClose={link => handleCreationForm(setOpenFluidTopicsTaskDialog, link)}
        />
      )}

      {openGitHubTaskDialog && (
        <CreateGitHubDialog
          rerunTask={rerunTask}
          open={openGitHubTaskDialog}
          onClose={link => handleCreationForm(setOpenGitHubTaskDialog, link)}
        />
      )}
    </Grid>
  );
}

FileDetailsTasks.propTypes = {
  file: PropTypes.instanceOf(FileEntity).isRequired,
};

export default FileDetailsTasks;
