import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import {
  MenuItem,
  Checkbox,
  Typography,
  ListItemText,
} from '@material-ui/core';
import * as yup from 'yup';
import Placeholder from '../common/Placeholder';
import CreateTaskDialog from './CreateTaskDialog';
import { applySearch, parsePath } from '../../core/services/fileStorageService';
import { createNormalizationTask, fetchConversionTags } from '../../redux/actions/taskActions';
import { showErrorSnackbar } from '../../redux/actions/appActions';
import {
  readFile,
  checkFileExist,
  getObjectsFromPath,
  getPathEntity,
} from '../../redux/actions/fileStorageActions';
import {
  ENTITY_TYPE_FILE, TASK_ENGINE_PYTHON, TASK_CONVERTER_NORMALIZATION,
} from '../../core/entities';
import { AUTOMATIC_REPORTS_MAPS, TASKS_ROUTE } from '../../core/constants';
import { getCorrectPath } from '../common/Utility';
import { formatDateStr } from '../../core/services/commonService';
import { conversionTagTooltip, displayTagValue } from '../../core/services/taskConversionService';
import { SHOW_TAG_LAST_MODIFIED_DATE } from '../../config';

const STEP_FIELD_TYPE_PATH = 'path';
const STEP_FIELD_TYPE_MULTIPLE = 'multiple';

const validationSchema = yup.object({
  conversionTag: yup.string().required('Release is required'),
  outputFilename: yup.string()
    .required('Output file name is required')
    .test(
      'start_end_with_space',
      'The output filename can not start or end with spaces',
      v => !(v.startsWith(' ') || v.endsWith(' ')),
    )
    .test(
      'allowed-characters',
    <>
      Output file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    v => /^[a-zA-Z0-9\s/\-._]+$/.test(v),
    )
    .test('not-new-lines', 'Output file name cannot contain the new lines', v => v && !v.includes('\n'))
    .test('is-zip', "Output file name should end with '.zip'", v => v && v.endsWith('.zip'))
    .test('not-starts-with-slash', "Output file name cannot contain '/'", v => v && !v.includes('/'))
    .test('is-file', 'Output file name cannot consist of an extension only', v => v && v !== '.zip'),
  sourceFilePath: yup.string()
    .required('Source file path is required')
    .test(
      'start_end_with_space',
      'The element of the source file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.sourceFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Source file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const { sourceFilePathSearchValue } = this.parent;
      const value = `${v}/${sourceFilePathSearchValue}`;

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Source file name cannot contain the new lines',
      function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        return value && !value.includes('\n');
      },
    )
    .test(
      'file-exist',
      'Source file does not exist or is not a file entity',
      async function (v) {
        const { sourceFilePathSearchValue } = this.parent;
        const value = `${v}/${sourceFilePathSearchValue}`;

        if (value) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  outputFolderPath: yup.string()
    .test(
      'required',
      'Output folder path is required',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && value !== '';
      },
    )
    .test(
      'start_end_with_space',
      'The element of the output folder path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Output folder can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

      return /^[a-zA-Z0-9\s/\-._]+$/.test(value);
    },
    )
    .test(
      'not-new-lines',
      'Output folder name cannot contain the new lines',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('\n');
      },
    )
    .test(
      'not-contain-extension',
      'Output folder name cannot contain the extensions',
      function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        return value && !value.includes('.zip');
      },
    )
    .test(
      'folder-exist',
      'Output folder does not exist or is not a folder entity',
      async function (v) {
        const value = getCorrectPath(v, this.parent.outputFolderPathSearchValue);

        if (value) {
          const parentResponse = await getPathEntity(value.split('/')[0]);
          if (!(
            parentResponse.status === 200
              && parentResponse.data.data.type !== ENTITY_TYPE_FILE
          )
          ) {
            return this.createError({ message: 'Output parent folder does not exist' });
          }

          const valueResponse = await getPathEntity(value);

          return valueResponse.status === 200 && valueResponse.data.data.type !== ENTITY_TYPE_FILE;
        }

        return false;
      },
    ),
  comparingFilePath: yup.string().nullable()
    .test(
      'start_end_with_space',
      'The element of the comparing file path can not start or end with spaces',
      function (v) {
        const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

        let result = true;
        value.split('/').forEach(path => {
          if (path.startsWith(' ') || path.endsWith(' ')) {
            result = false;
          }
        });

        return result;
      },
    )
    .test(
      'allowed-characters',
    <>
      Comparing file name can contain only the next characters:
      <br />
      {'0-9, a-z, A-Z, space, \'.\', \'-\', \'_\' and \'/\'.'}
    </>,
    function (v) {
      const value = getCorrectPath(v, this.parent.comparingFilePathSearchValue);

      if (!(value === null || value === undefined) && !/^[a-zA-Z0-9\s/\-._]+$/.test(value)) return false;

      return true;
    },
    )
    .test(
      'not-new-lines',
      'Comparing file name cannot contain the new lines',
      function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined) && value.includes('\n')) return false;

        return true;
      },
    )
    .test(
      'not-file-exist',
      'Comparing file does not exist or is not a file entity',
      async function (v) {
        const { comparingFilePathSearchValue } = this.parent;
        const value = `${v}/${comparingFilePathSearchValue}`;

        if (!(value === null || value === undefined)) {
          const response = await getPathEntity(value);

          return response.status === 200 && response.data.data.type === ENTITY_TYPE_FILE;
        }

        return true;
      },
    ),
});

const DEFAULT_CHECKBOX_VALUES = {
  normalize_elements: true,
  use_topic_type_as_prefix: false,
  use_hash_as_suffix: false,
  use_hash_instead_of_refs: true,
};

const generateInitValues = task => {
  const [comparingFilePath, comparingFileName] = parsePath(task.expectedResult || '');
  const [outputFolderPath, outputFilename] = parsePath(task.output || '');
  const [sourceFilePath, sourceFilename] = parsePath(task.source || '');
  const config = {
    ...DEFAULT_CHECKBOX_VALUES, ...(task.config ? JSON.parse(task.config) : {}),
  };

  return {
    conversionTag: task.tag || '',
    outputFilename: outputFilename || '',

    sourceFilePath: sourceFilePath || '',
    sourceFilePathSearchValue: sourceFilename || '',
    sourceFilePathObjects: [],

    outputFolderPath,
    outputFolderPathSearchValue: '',
    outputFolderPathObjects: [],

    comparingFilePath: comparingFilePath || '',
    comparingFilePathSearchValue: comparingFileName || '',
    comparingFilePathObjects: [],

    useHashAsSuffix: config.use_hash_as_suffix,
    normalizeElements: config.normalize_elements,
    useTopicTypeAsPrefix: config.use_topic_type_as_prefix,
    useHashInsteadOfRefs: config.use_hash_instead_of_refs,

    reports: task.reports || ['manifest-report', 'info-types-report', 'elements-information-report'],

    createFolders: false,
    config: {},
  };
};

function filterObjects(objects, searchValue) {
  return applySearch(objects, searchValue).sort((a, b) => b.type.localeCompare(a.type));
}

function CreateNormalizationDialog(props) {
  const {
    open,
    onClose,
    rerunTask,
    resetOnClose,
  } = props;

  const dispatch = useDispatch();

  const [creatingTask, setCreatingTask] = useState(false);

  const [conversionTags, setConversionTags] = useState([]);
  const [loadingConversionTags, setLoadingConversionTags] = useState(false);

  const [pythonParams, setPythonParams] = useState({});
  const [loadingPythonParams, setLoadingPythonParams] = useState(false);

  const [outputFileWarning, setOutputFileWarning] = useState(undefined);
  const [checkingOutputFile, setCheckingOutputFile] = useState(false);

  const [loadingSourceFileObjects, setLoadingSourceFileObjects] = useState(false);
  const [loadingOutputFolderObjects, setLoadingOutputFolderObjects] = useState(false);
  const [loadingComparingFileObjects, setLoadingComparingFileObjects] = useState(false);

  const [goToLastStep] = useState(Object.keys(rerunTask).length > 4);

  const [rerunConfig] = useState(rerunTask && rerunTask.config ? rerunTask.config : undefined);

  let loading = false;

  if (goToLastStep) {
    loading = [
      checkingOutputFile,
      loadingPythonParams,
      loadingConversionTags,
      loadingSourceFileObjects,
      loadingOutputFolderObjects,
      loadingComparingFileObjects,
    ].some(v => v === true);
  }

  useEffect(() => {
    setLoadingConversionTags(true);

    dispatch(fetchConversionTags(TASK_ENGINE_PYTHON, TASK_CONVERTER_NORMALIZATION))
      .then(tagEntities => {
        if (!tagEntities) {
          dispatch(showErrorSnackbar('Can not load the releases for the normalization'));
          return [];
        }

        return tagEntities.sort((a, b) => -a.updated.localeCompare(b.updated));
      })
      .then(setConversionTags)
      .finally(() => setLoadingConversionTags(false));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: generateInitValues(rerunTask || {}),
    validationSchema,
    validateOnChange: false,
    validateOnMount: false,
    validateOnBlur: false,
  });

  const handleClose = link => {
    onClose(link);

    formik.resetForm();
  };

  const onFormSubmit = values => {
    let outputFolder = `${values.outputFolderPath}${values.outputFolderPath.endsWith('/') ? '' : '/'}`;
    if (values.outputFolderPathSearchValue !== '') {
      outputFolder += `${values.outputFolderPathSearchValue}${values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
    }

    const task = {
      tag: values.conversionTag,
      engine: TASK_ENGINE_PYTHON,
      converter: TASK_CONVERTER_NORMALIZATION,
      output: `${outputFolder}${values.outputFilename}`,
      source: `${values.sourceFilePath}${values.sourceFilePath.endsWith('/') ? '' : '/'}${values.sourceFilePathSearchValue}`,
      config: JSON.stringify(),
      reports: values.reports,
    };

    if (values.comparingFilePath) {
      task.expected_result = `${values.comparingFilePath}${values.comparingFilePath.endsWith('/') ? '' : '/'}${values.comparingFilePathSearchValue}`;
    }

    const config = {
      normalize_elements: values.normalizeElements,
      use_topic_type_as_prefix: values.useTopicTypeAsPrefix,
      use_hash_as_suffix: values.useHashAsSuffix,
      use_hash_instead_of_refs: values.useHashInsteadOfRefs,
    };

    if (Object.prototype.hasOwnProperty.call(pythonParams, 'fields')) {
      Object.keys(pythonParams.fields).forEach(key => {
        if (values.config[key].value !== '') config[key] = values.config[key];
      });
    }

    if (Object.prototype.hasOwnProperty.call(pythonParams, 'configurations')) {
      Object.keys(pythonParams.configurations).forEach(key => {
        if (values.config[key].value !== '') config[key] = values.config[key];
      });
    }

    if (Object.keys(config).length > 0) task.config = JSON.stringify(config);

    dispatch(createNormalizationTask(task, values.env))
      .then(res => handleClose(`${TASKS_ROUTE}/${res.id}`))
      .catch(() => setCreatingTask(false));
  };

  const onCreateButtonClick = () => {
    if (creatingTask) return;
    setCreatingTask(true);

    onFormSubmit(formik.values);
  };

  useEffect(() => {
    if (open) {
      setLoadingSourceFileObjects(true);

      getObjectsFromPath(`/${formik.values.sourceFilePath}`)
        .then(objects => {
          formik.setFieldValue('sourceFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('sourceFilePath').then(() => setLoadingSourceFileObjects(false));
            });
        });
    }
  }, [formik.values.sourceFilePath, open]);

  useEffect(() => {
    if (open) {
      setLoadingOutputFolderObjects(true);

      const value = getCorrectPath(formik.values.outputFolderPath, '');

      getObjectsFromPath(`/${value}`)
        .then(objects => {
          formik.setFieldValue('outputFolderPathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('outputFolderPath').then(() => setLoadingOutputFolderObjects(false));
            });
        });
    }
  }, [formik.values.outputFolderPath, open]);

  useEffect(() => {
    if (open) {
      setLoadingComparingFileObjects(true);

      getObjectsFromPath(`/${formik.values.comparingFilePath}`)
        .then(objects => {
          formik.setFieldValue('comparingFilePathObjects', objects.sort((a, b) => b.type.localeCompare(a.type)))
            .then(() => {
              formik.validateField('comparingFilePath').then(() => setLoadingComparingFileObjects(false));
            });
        });
    }
  }, [formik.values.comparingFilePath, open]);

  useEffect(() => {
    if (open && formik.values.outputFilename !== '') formik.validateField('outputFilename');
  }, [formik.values.outputFilename, open]);

  useEffect(() => {
    if (open && formik.values.conversionTag !== '') formik.validateField('conversionTag');
  }, [formik.values.conversionTag, open]);

  const onChangePathValue = (value, fieldName, searchFieldName) => {
    const split = value.split('/');

    const search = split[split.length - 1];
    const path = `${split.slice(0, -1).join('/')}`;

    if (path !== '/' || formik.values[fieldName] !== path) formik.setFieldValue(fieldName, path);
    formik.setFieldValue(searchFieldName, search).then(() => formik.validateField(fieldName));
  };

  let allowCreateFolders = false;
  if (formik.errors.outputFolderPath !== undefined) {
    if (formik.errors.outputFolderPath === 'Output folder does not exist or is not a folder entity') {
      allowCreateFolders = true;
    }
  }

  let outputFolder = `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}`;
  if (formik.values.outputFolderPathSearchValue !== '') {
    outputFolder += `${formik.values.outputFolderPathSearchValue}${formik.values.outputFolderPathSearchValue.endsWith('/') ? '' : '/'}`;
  }

  const clearPythonParams = params => {
    if (Object.prototype.hasOwnProperty.call(params, 'fields')) {
      Object.keys(params.fields).forEach(key => {
        delete formik.values.config[key];
      });
    }

    if (Object.prototype.hasOwnProperty.call(params, 'configurations')) {
      Object.keys(params.configurations).forEach(key => {
        delete formik.values.config[key];
      });
    }
  };

  const loadPythonParams = () => {
    setLoadingPythonParams(true);

    dispatch(
      readFile(`repo-python-converters-params/${TASK_CONVERTER_NORMALIZATION}/ryffine.converter-${formik.values.conversionTag}-params.json`),
    )
      .then(res => setPythonParams(oldParams => {
        clearPythonParams(oldParams);

        const config = JSON.parse(rerunConfig || '{}');

        const params = JSON.parse(res.data.data);
        if (Object.prototype.hasOwnProperty.call(params, 'fields')) {
          Object.keys(params.fields).forEach(key => {
            formik.setFieldValue(
              `config.${key}`,
              config[key] || params.fields[key].default,
            );
          });
        }

        if (Object.prototype.hasOwnProperty.call(params, 'configurations')) {
          Object.keys(params.configurations).forEach(key => {
            formik.setFieldValue(
              `config.${key}`,
              config[key] || params.configurations[key].default,
            );
          });
        }

        return params;
      }))
      .catch(() => setPythonParams(oldParams => {
        clearPythonParams(oldParams);

        return [];
      }))
      .finally(() => setLoadingPythonParams(false));
  };

  const fields = [];
  const configurations = [];

  let allowContinue = true;

  if (pythonParams && Object.keys(pythonParams).length > 0) {
    if (Object.prototype.hasOwnProperty.call(pythonParams, 'fields')) {
      const errorFieldCheck = (key, value) => (value === undefined ? `The ${key.replaceAll('_', ' ')} could not be empty` : undefined);

      const errorListFieldCheck = (key, value) => (value.length === 0 ? `At least one of the ${key.replaceAll('_', ' ')} should be selected` : undefined);

      Object.keys(pythonParams.fields).forEach(key => {
        if (Object.prototype.hasOwnProperty.call(formik.values.config, key)) {
          const isRelated = Object.keys(pythonParams.fields[key]).includes('related_to');

          const fieldDisabled = isRelated
            ? !(formik.values[camelCase(pythonParams.fields[key].related_to)])
            : false;

          let fieldError;
          if (!fieldDisabled) {
            fieldError = Array.isArray(formik.values.config[key])
              ? errorListFieldCheck(key, formik.values.config[key])
              : errorFieldCheck(key, formik.values.config[key]);
          }

          let field = {};
          if (pythonParams.fields[key].choices) {
            field = {
              item: value => {
                if (Object.prototype.hasOwnProperty.call(formik.values.config, key)) {
                  if (pythonParams.fields[key].multiple) {
                    return (
                      <MenuItem key={value} value={value}>
                        <Checkbox
                          checked={formik.values.config[key].indexOf(value) > -1}
                          style={{ padding: '0 9px 0 0' }}
                        />
                        <ListItemText primary={value} />
                      </MenuItem>
                    );
                  }

                  return <MenuItem key={value} value={value}>{value}</MenuItem>;
                }

                return undefined;
              },
              changeValue: value => {
                setTimeout(
                  () => formik.setFieldValue(`config.${key}`, [...value]),
                  0,
                );
              },
              onChange: event => {
                const { value } = event.target;
                if (Array.isArray(value) && value[value.length - 1] === 'all') {
                  setTimeout(
                    () => {
                      const { choices } = pythonParams.fields[key];
                      const currentValue = formik.values.config[key];

                      let listValue = choices;
                      if (
                        currentValue.length === choices.length
                        || (currentValue.length !== 0 && currentValue.length !== choices.length)
                      ) {
                        listValue = [];
                      }

                      formik.setFieldValue(`config.${key}`, [...listValue]);
                    },
                    0,
                  );
                  return;
                }

                formik.handleChange(event);
              },
              enableSelectAll: pythonParams.fields[key].multiple,
              multiple: pythonParams.fields[key].multiple,
              tooltip: pythonParams.fields[key].tooltip
                ? <span dangerouslySetInnerHTML={{ __html: pythonParams.fields[key].tooltip }} />  // eslint-disable-line
                : undefined,
              items: pythonParams.fields[key].choices,
              label: pythonParams.fields[key].label,
              value: formik.values.config[key],
              renderValue: value => {
                if (value === '') {
                  return (
                    <Placeholder>{pythonParams.fields[key].label}</Placeholder>
                  );
                }

                if (Array.isArray(value)) return value.join(', ');
                return value;
              },
              renderBottom: isRelated,
              disabled: fieldDisabled,
              key: `config.${key}`,
              error: fieldError,
            };
          } else {
            field = {
              tooltip: pythonParams.fields[key].tooltip,
              onChange: event => formik.handleChange(event),
              error: errorFieldCheck(key, formik.values.config[key]),
              value: formik.values.config[key],
              label: pythonParams.fields[key].label,
              key: `config.${key}`,
            };
          }

          if (field.error) allowContinue = false;

          fields.push(field);
        }
      });
    }

    if (Object.prototype.hasOwnProperty.call(pythonParams, 'configurations')) {
      Object.keys(pythonParams.configurations).forEach(key => {
        const isRelated = Object.keys(pythonParams.configurations[key]).includes('related_to');
        const fieldDisabled = isRelated
          ? !(formik.values[camelCase(pythonParams.configurations[key].related_to)])
          : false;

        configurations.push({
          tooltip: pythonParams.configurations[key].tooltip
            ? <span dangerouslySetInnerHTML={{ __html: pythonParams.configurations[key].tooltip }} />  // eslint-disable-line
            : undefined,
          onChange: event => formik.handleChange(event),
          label: pythonParams.configurations[key].label,
          value: formik.values.config[key],
          disabled: fieldDisabled,
          key: `config.${key}`,
        });
      });
    }
  }

  const config = {
    title: 'Create Normalization',
    steps: [
      {
        type: STEP_FIELD_TYPE_MULTIPLE,
        title: 'Task details',
        fields: [
          {
            item: tag => (
              <MenuItem key={tag.name} value={tag.name}>
                <Typography>{tag.name}</Typography>
                <Typography className="ml-1" variant="caption" color="textSecondary">
                  {formatDateStr(tag.updated)}
                </Typography>
              </MenuItem>
            ),
            tooltip: SHOW_TAG_LAST_MODIFIED_DATE
              ? conversionTagTooltip(formik.values.conversionTag, conversionTags)
              : undefined,
            displayValue: SHOW_TAG_LAST_MODIFIED_DATE
              ? displayTagValue(formik.values.conversionTag, conversionTags)
              : undefined,
            onChange: event => formik.handleChange(event),
            value: formik.values.conversionTag,
            error: formik.errors.conversionTag,
            items: conversionTags,
            renderValue: value => {
              if (value === '') return <Placeholder>Release</Placeholder>;

              return value;
            },
            key: 'conversionTag',
            label: 'Release',
          },
          {
            item: report => (
              <MenuItem key={report.key} value={report.key}>
                <Checkbox
                  checked={formik.values.reports.indexOf(report.key) > -1}
                  style={{ padding: '0 9px 0 0' }}
                />
                <ListItemText primary={report.name} />
              </MenuItem>
            ),
            onChange: event => {
              const { value } = event.target;
              if (Array.isArray(value) && value[value.length - 1] === 'all') {
                setTimeout(
                  () => {
                    const currentValue = formik.values.reports;

                    let listValue = AUTOMATIC_REPORTS_MAPS.map(r => r.key);
                    if (
                      currentValue.length === AUTOMATIC_REPORTS_MAPS.length
                      || (currentValue.length !== 0
                        && currentValue.length !== AUTOMATIC_REPORTS_MAPS.length)
                    ) {
                      listValue = [];
                    }

                    formik.setFieldValue('reports', [...listValue]);
                  },
                  0,
                );
                return;
              }

              formik.handleChange(event);
            },
            multiple: true,
            enableSelectAll: true,
            value: formik.values.reports,
            error: formik.errors.reports,
            renderValue: value => {
              if (value.length === 0) return <Placeholder>Automatic reports</Placeholder>;

              if (Array.isArray(value)) {
                const displayValue = [];
                AUTOMATIC_REPORTS_MAPS.forEach(report => {
                  if (formik.values.reports.includes(report.key)) {
                    displayValue.push(report.name);
                  }
                });

                return displayValue.join(', ');
              }

              return value;
            },
            items: AUTOMATIC_REPORTS_MAPS,
            placeholder: 'Automatic reports',
            label: 'Automatic reports',
            key: 'reports',
          },
          ...fields,
        ],
        configurations: [
          {
            tooltip: (
              <>
                <p>
                  If you select the &quot;Use topic type as a prefix in normalized file names&quot;
                  option, the files (indicated like the common) will contain the topic type in
                  the filename like a prefix.
                </p>
                <p>
                  Notice. This option works only with dita files
                  (files with .ditamap, .dita, or .xml extensions).
                </p>
                <p>
                  For example, the &quot;test_file.xml&quot; will be
                  &quot;concept_test_file.xml&quot;
                </p>
                <p>
                  Important. The option could be used with the &quot;Use hash as a suffix in
                  normalized file names&quot; option.
                </p>
              </>
            ),
            label: 'Use topic type as a prefix in normalized file names',
            onChange: event => formik.handleChange(event),
            value: formik.values.useTopicTypeAsPrefix,
            key: 'useTopicTypeAsPrefix',
          },
          {
            tooltip: (
              <>
                <p>
                  If you select the &quot;Use hash as a suffix in normalized file names&quot;
                  option, the files (indicated like the common) will contain the hash
                  calculated from the content in the filename like a suffix.
                </p>
                <p>
                  Notice. This option works only with dita files
                  (files with .ditamap, .dita, or .xml extensions).
                </p>
                <p>
                  For example, the &quot;test_file.xml&quot; will be
                  &quot;test_file_460049165413ed9fcaf2a990d196a08c.xml&quot;
                </p>
                <p>
                  Important. The option could be used with the &quot;Use topic type as a prefix
                  in normalized file names&quot; option.
                </p>
              </>
            ),
            label: 'Use hash as a suffix in normalized file names',
            onChange: event => formik.handleChange(event),
            value: formik.values.useHashAsSuffix,
            key: 'useHashAsSuffix',
          },
          {
            tooltip: (
              <p>
                Choosing this option de-duplicates all topics in all folders, but increases run
                time. De-selecting this option runs faster but allows duplicate files to exist
                in different folders.
              </p>
            ),
            onChange: event => formik.handleChange(event),
            label: 'De-duplicate topics in all folders',
            value: formik.values.useHashInsteadOfRefs,
            key: 'useHashInsteadOfRefs',
          },
          {
            tooltip: (
              <>
                <p>
                  If you select the &quot;Normalize elements&quot; option, we will search for the
                  elements (section, table, steps, note, etc) that contain the same
                  child content (ignores the &quot;id&quot; attributes). Based on matches will
                  be created with the &quot;common&quot; file and added the reference instead
                  of the element into the required files.
                </p>
                <p>
                  Notice. This option works only with dita files
                  (files with .ditamap, .dita, or .xml extensions).
                </p>
              </>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.normalizeElements,
            label: 'Normalize elements',
            key: 'normalizeElements',
          },
          ...configurations,
        ],
        stepTooltip: (
          configurations.length > 0 && fields.length > 0
            ? 'As of release 1.0.0, you can select the client for normalization. You must specify the client if the "referable-content" type is required. Otherwise, select the default client. If your content contains non-Oasis DITA doctypes, normalization will automatically collect doctypes from the content and use them for the common files.'
            : undefined
        ),
        isValid: (formik.values.conversionTag !== '' && formik.errors.conversionTag === undefined),
        allowContinue: formik.values.conversionTag !== '' && allowContinue,
        onSubmit: () => Promise.resolve(true),
        loading: false,
      },
      {
        title: 'Source file path',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.sourceFilePathObjects, formik.values.sourceFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'sourceFilePath', 'sourceFilePathSearchValue'),
          value: formik.values.sourceFilePath !== ''
            ? `${formik.values.sourceFilePath}${formik.values.sourceFilePath.endsWith('/') ? '' : '/'}${formik.values.sourceFilePathSearchValue}`
            : formik.values.sourceFilePathSearchValue,
          error: formik.errors.sourceFilePath,
          loading: false,
        },
        allowContinue: formik.values.sourceFilePath !== '',
        isValid: formik.errors.sourceFilePath === undefined,
        onSubmit: () => Promise.resolve(true),
        loading: loadingSourceFileObjects,
      },
      {
        title: 'Output file path',
        type: STEP_FIELD_TYPE_PATH,
        displayValue: `${outputFolder}${formik.values.outputFilename}`,
        displayNode: (
          <span
            dangerouslySetInnerHTML={{__html: `${outputFolder.replaceAll(' ', '&nbsp;').replaceAll('/', ' / ')}<b>${formik.values.outputFilename.replaceAll(' ', '&nbsp;')}</b>`}}  // eslint-disable-line
          />
        ),
        fields: [
          {
            onChange: event => formik.handleChange(event),
            value: formik.values.outputFilename,
            error: formik.errors.outputFilename,
            placeholder: 'Example.zip',
            label: 'Output file name',
            key: 'outputFilename',
          },
        ],
        pathField: {
          label: 'Output folder path',
          placeholder: 'Search a folder by name',
          objects: filterObjects(
            formik.values.outputFolderPathObjects, formik.values.outputFolderPathSearchValue,
          ).filter(o => o.type === 'folder'),
          onChange: value => {
            onChangePathValue(value, 'outputFolderPath', 'outputFolderPathSearchValue');
            formik.setFieldValue('createFolders', false);
          },
          value: formik.values.outputFolderPath !== ''
            ? `${formik.values.outputFolderPath}${formik.values.outputFolderPath.endsWith('/') ? '' : '/'}${formik.values.outputFolderPathSearchValue}`
            : formik.values.outputFolderPathSearchValue,
        },
        configurations: allowCreateFolders ? [
          {
            tooltip: (
              <p>
                <p>
                  The output folder does not exist. If you select this parameter,
                  the folder will be created automatically.
                </p>
                <p>
                  <b>Notice. </b>
                  Use this parameter carefully.
                </p>
                <p>
                  THE PARENT FOLDER MUST EXIST.
                </p>
              </p>
            ),
            onChange: event => formik.handleChange(event),
            value: formik.values.createFolders,
            label: 'Create Folders',
            key: 'createFolders',
          },
        ] : [],
        allowContinue: formik.values.outputFolderPath !== ''
          && (formik.errors.outputFolderPath === undefined || formik.values.createFolders)
          && formik.values.outputFilename !== '',
        isValid: formik.values.outputFolderPath !== ''
          && (formik.values.outputFilename !== '' && formik.errors.outputFilename === undefined),
        onSubmit: () => Promise.resolve(true),
        error: formik.errors.outputFolderPath,
        loading: loadingOutputFolderObjects,
        warning: outputFileWarning,
      },
      {
        title: 'Comparing file (optional)',
        type: STEP_FIELD_TYPE_PATH,
        pathField: {
          objects: filterObjects(
            formik.values.comparingFilePathObjects, formik.values.comparingFilePathSearchValue,
          ),
          onChange: value => onChangePathValue(value, 'comparingFilePath', 'comparingFilePathSearchValue'),
          value: formik.values.comparingFilePath !== ''
            ? `${formik.values.comparingFilePath}${formik.values.comparingFilePath.endsWith('/') ? '' : '/'}${formik.values.comparingFilePathSearchValue}`
            : formik.values.comparingFilePathSearchValue,
          error: formik.errors.comparingFilePath,
        },
        isValid: (formik.values.comparingFilePathSearchValue !== '' || formik.values.comparingFilePath !== '')
          ? formik.errors.comparingFilePath === undefined : true,
        onSubmit: () => Promise.resolve(true),
        loading: loadingComparingFileObjects,
        allowContinue: true,
      },
    ],
    onSubmit: () => onCreateButtonClick(),
    seeValuesAfterActiveStep: true,
    allowAnyStepSelection: true,
    loading: creatingTask,
    initActiveStep: 0,
  };

  useEffect(() => {
    if (formik.values.conversionTag !== '') {
      formik.validateField('conversionTag').then(() => loadPythonParams());
    }
  }, [formik.values.conversionTag, open]);

  useEffect(() => {
    setCheckingOutputFile(true);

    const fileId = `${outputFolder}${formik.values.outputFilename}`;

    if (formik.values.outputFolderPath === outputFolder) {
      const exist = formik.values.outputFolderPathObjects.some(file => file.id === fileId);

      if (exist) setOutputFileWarning('The output file already exists');
      else setOutputFileWarning(undefined);

      setCheckingOutputFile(false);
    } else {
      dispatch(checkFileExist(fileId))
        .then(() => setOutputFileWarning('The output file already exists'))
        .catch(() => setOutputFileWarning(undefined))
        .finally(() => setCheckingOutputFile(false));
    }
  }, [
    formik.values.outputFolderPath,
    formik.values.outputFolderPathSearchValue,
    formik.values.outputFolderPathObjects,
    formik.values.outputFilename,
  ]);

  if (goToLastStep) config.initActiveStep = config.steps.length;

  return (
    <CreateTaskDialog
      open={open}
      config={config}
      loading={loading}
      onClose={() => {
        onClose();

        if (resetOnClose) {
          formik.setValues(generateInitValues({}));
        }
      }}
      resetOnClose={resetOnClose}
    />
  );
}

CreateNormalizationDialog.defaultProps = { rerunTask: {}, resetOnClose: true };

CreateNormalizationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  resetOnClose: PropTypes.bool,
  rerunTask: PropTypes.object,
};

export default CreateNormalizationDialog;
