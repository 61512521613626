import {
  FETCH_EVALUATION_SCORE_START,
  FETCH_EVALUATION_SCORE_SUCCESS,
  FETCH_EVALUATION_SCORE_FAIL,
  FETCH_EVALUATION_PDF_START,
  FETCH_EVALUATION_PDF_SUCCESS,
  FETCH_EVALUATION_PDF_FAIL,
  FETCH_EVALUATION_HTML_START,
  FETCH_EVALUATION_HTML_SUCCESS,
  FETCH_EVALUATION_HTML_FAIL,
} from '../actionTypes';

import { actionFail } from './appActions';
import { readZipFileContent } from './fileStorageActions';

export function fetchEvaluationScores(taskId) {
  return function (dispatch) {
    const filepath = `ryffine-reports/evaluation/${taskId}.zip`;

    dispatch({ type: FETCH_EVALUATION_SCORE_START });
    dispatch({ type: FETCH_EVALUATION_HTML_START });
    dispatch({ type: FETCH_EVALUATION_PDF_START });

    return readZipFileContent(filepath)
      .then(zip => {
        Object.keys(zip.files).forEach(zipFile => {
          if (zipFile.endsWith('.json')) {
            zip.file(zipFile).async('string')
              .then(text => dispatch({
                type: FETCH_EVALUATION_SCORE_SUCCESS,
                payload: JSON.parse(text),
              }))
              .catch(error => {
                dispatch(actionFail(FETCH_EVALUATION_SCORE_FAIL, error.response));
              });
          }

          if (zipFile.endsWith('.pdf')) {
            zip.file(zipFile).async('blob')
              .then(blob => dispatch({
                type: FETCH_EVALUATION_PDF_SUCCESS,
                payload: blob,
              }))
              .catch(error => {
                dispatch(actionFail(FETCH_EVALUATION_PDF_FAIL, error.response));
              });
          }

          if (zipFile.endsWith('.zip')) {
            zip.file(zipFile).async('blob')
              .then(blob => dispatch({
                type: FETCH_EVALUATION_HTML_SUCCESS,
                payload: blob,
              }))
              .catch(error => {
                dispatch(actionFail(FETCH_EVALUATION_HTML_FAIL, error.response));
              });
          }
        });
      })
      .catch(error => {
        dispatch(actionFail(FETCH_EVALUATION_SCORE_FAIL, error.response));
        dispatch(actionFail(FETCH_EVALUATION_HTML_FAIL, error.response));
        dispatch(actionFail(FETCH_EVALUATION_PDF_FAIL, error.response));
      });
  };
}
